import * as React from 'react';

type Machine<s> = { [k: string]: { [k: string]: S } };
type MachineState<t> = keyof T;
type MachineEvent<t> = keyof UnionToIntersection<t[keyof T]="">;

// 🤯 https://fettblog.eu/typescript-union-to-intersection/
type UnionToIntersection<t> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any
  ? R
  : never;

export function useStateMachine<m>(
  initialState: MachineState<m>,
  machine: M & Machine<machinestate<m>>
) {
  return React.useReducer((state: MachineState<m>, event: MachineEvent<m>): MachineState<m> => {
    const nextState = (machine[state] as any)[event];
    return nextState ?? state;
  }, initialState);
}
</m></m></m></machinestate<m></m></m></t></t[keyof></t></t></s>